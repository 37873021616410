import React from 'react'
import Helmet from 'react-helmet'
import bolt from '../images/bolt-black.svg'
// import Navbar from './Navbar'

import '../styles/all.scss'

const TemplateWrapper = ({ children }) => (
  <div className="App">
    <Helmet title="Mad City" />
    {/* <Navbar /> */}
    {/* <div>{children}</div> */}
    <img src={bolt} className="Bolt" alt="Mad City Media Group 2020" />
  </div>
)

export default TemplateWrapper
