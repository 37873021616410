import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <section>
        <h1>{title}</h1>
        {posts.map(({ node: post }) => (
          <div key={post.id}>
            <p>
              <Link to={post.slug}>{post.title}</Link>
              <span> &bull; </span>
              <small>
                {post.date} - posted by{' '}
                <Link to={`/author/${post.author.slug}`}>
                  {post.author.name}
                </Link>
              </small>
            </p>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: post.excerpt.replace(/<p class="link-more.*/, ''),
                }}
              />
              <Link to={post.slug}>Keep Reading →</Link>
            </div>
          </div>
        ))}
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    date(formatString: "MMMM DD, YYYY")
    slug
  }
`
